<template>
  <div class="edit-agency-user-role">
    <div class="card">
      <div class="content">
        <fd-form-section
          title="Role Info"
          class="d-flex align-items-end mt-2 px-3"
        >
          <fd-input
            v-model="roleToEdit.name"
            class="col-12 sm-col-6 px-1 mb-2"
            label="Role Name"
            name="name"
            type="text"
          >
          </fd-input>
          <fd-textarea
            v-model="roleToEdit.description"
            class="col-12 px-1 mb-2"
            label="Description"
            name="description"
            placeholder="Write some description about this role (optional)"
          >
          </fd-textarea>
        </fd-form-section>

        <!-- Admin/Branch Level Control -->
        <div class="col-12 my-2 mx-2">
          <div
            class="bordered-card p-2"
            :style="{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
              columnGap: '8px',
              rowGap: '8px'
            }"
          >
            <div class="d-flex align-items-center">
              <div class="p-2">
                <div class="d-flex align-items-center my-1">
                  <div>Agency Admin</div>
                  <toggle-switch
                    v-model="roleToEdit.isAgencyAdmin"
                    class="sm ml-2"
                  ></toggle-switch>
                </div>
                <div class="d-flex align-items-center my-1">
                  <div>Branch Admin</div>
                  <toggle-switch
                    v-model="roleToEdit.isBranchAdmin"
                    class="sm ml-2"
                  ></toggle-switch>
                </div>
              </div>
            </div>
            <div class="bg-grey-100 fg-grey-800 d-flex p-2">
              <i class="fas fa-circle-info mr-2 mt-1"></i>
              <div>
                <p class="mb-1">
                  Leave both the toggles off if the role is neither branch or
                  agency admin.
                </p>
                <p>
                  If toggled on, Agency Admin will have agency level control for
                  the enabled permissions, while Branch Admin have only branch
                  level control (Cannot see other branch's data).
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Permission -->
        <fd-form-section class="px-3" title="Permission">
          <div class="col-12 d-flex justify-content-end">
            <button
              type="button"
              class="btn main bordered"
              @click="clearPermission"
            >
              Clear
            </button>
            <button
              type="button"
              class="btn main bordered ml-1"
              @click="selectAllPermission"
            >
              Select All
            </button>
          </div>

          <div
            v-for="(permissions, permIndex) in roleToEdit.permission"
            :key="permIndex"
            :class="permissionSectionClass"
          >
            <div>{{ _.startCase(permIndex) }}</div>
            <div class="my-2">
              <div
                v-for="(permission, fieldIndex) in permissions"
                :key="fieldIndex"
              >
                <fd-checkbox
                  v-model="roleToEdit.permission[permIndex][fieldIndex]"
                  class="col-12 sm-col-6 md-col-3"
                  :label="_.startCase(fieldIndex)"
                  :name="fieldIndex"
                >
                </fd-checkbox>
              </div>
            </div>
          </div>
        </fd-form-section>
      </div>
      <!-- Button -->
      <div class="footer col-12 p-2 text-right">
        <button class="btn mx-1" @click="$emit('close')">Cancel</button>
        <button class="btn main mx-1" @click="update">Update</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ToggleSwitch: () => import("@/components/GlobalComponents/ToggleSwitch")
  },
  mixins: [],
  props: {
    roleData: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      roleToEdit: {
        isAgencyAdmin: false,
        isBranchAdmin: false
      },
      permissionSectionClass:
        "col-12 sm-col-6 md-col-4 pb-3 px-2 permission-section"
    };
  },
  computed: {},
  watch: {
    "roleToEdit.isAgencyAdmin": {
      handler(val) {
        if (this.roleToEdit.isBranchAdmin && val) {
          this.roleToEdit.isBranchAdmin = false;
        }
      }
    },
    "roleToEdit.isBranchAdmin": {
      handler(val) {
        if (this.roleToEdit.isAgencyAdmin && val) {
          this.roleToEdit.isAgencyAdmin = false;
        }
      }
    }
  },
  created: function () {
    this.roleToEdit = this._.cloneDeep(this.roleData);
  },
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    update() {
      this.$emit("update", this.roleToEdit);
    },
    clearPermission() {
      for (let permission in this.roleToEdit.permission) {
        this.roleToEdit.permission[permission] = this._.mapValues(
          this.roleToEdit.permission[permission],
          () => false
        );
      }
    },
    selectAllPermission() {
      for (let permission in this.roleToEdit.permission) {
        this.roleToEdit.permission[permission] = this._.mapValues(
          this.roleToEdit.permission[permission],
          () => true
        );
      }
    }
  }
};
</script>

<style lang="scss">
.edit-agency-user-role {
  .content {
    max-height: 80vh;
    overflow-y: auto;
  }
  .header {
    background: #f8f8f8;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .description {
    font-size: 14px;
  }
  .permission {
    .permission-section {
      font-size: 16px;
    }
  }
  .footer {
    box-shadow: 0px -2px 5px -2px #00000025;
  }
}
</style>
